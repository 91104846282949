.footer-container {
    /* position: absolute; */
    bottom: 0;
    width: 100vw;
    height: 32vh;
    background-color: var(--light-blue);
}

.footer-container .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-container .content hr {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 45%;
    width: 55%;
}

.footer-container h3 {
    color: var(--golden);
}

.footer-container .content div {
    display: flex;
    width: calc(80vw/3);
    justify-content: center;
}

.footer-container .content #footer-contact {
    padding-top: 20px;
    display: block;
}

.footer-container .content #footer-social-media {
    padding-top: 20px;
    display: block;
}

.footer-container .content #footer-important-links {
    padding-top: 20px;
    display: block;
}

img {
    width: 15vw;
}

.footer-container .logo-img {
    width: 20vw;
    justify-content: center;
}

.footer-container .copyright {
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
}

.footer-container .copyright p {
    height: 2vh;
    width: 100vw;
    font-size: 0.8rem;
    text-align: center;
}

.footer-container ul {
    margin-top: 5px;
    list-style-type: disc;
}

.footer-container li {
    margin-bottom: 4px;
    width: 20vw;
}

/* estilo padrão do link */
.footer-container a {
    color: var(--white);
    text-decoration: disc;
}

/* estilo do link com mouse sobrevoando */
.footer-container a:hover {
    font-weight: 600;
    text-decoration-color: var(--golden);
}